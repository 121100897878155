<template>
  <div class="d-flex align-center table-targets py-2 flex-wrap">
    <TargetIcon
      v-for="(target, index) in items"
      :key="index"
      class="ma-1"
      :target="target.code"
    />
  </div>
</template>

<script>
import TargetIcon from '@/components/reusable/TargetIcon.vue';

export default {
  components: { TargetIcon },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>
